import { AccountInfo, EventType, PublicClientApplication } from "@azure/msal-browser";
import { checkLoggedIn, msalConfig, useLogin } from "./authConfig";
import { useEffect, useState } from "react";
import { MsalProvider } from "@azure/msal-react";
import { LoginContext } from "./loginContext";
import Layout from "./pages/layout/Layout";
import { LoginButton } from "./components/LoginButton";
import { useNavigate } from "react-router-dom";
import { validateToken } from "./api";

import OmniReachLogo from "./assets/OmniReachLogo.png"

const LayoutWrapper = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [msalInstance] = useState(() =>
        useLogin ? new PublicClientApplication(msalConfig) : null
    );
    const navigate = useNavigate()

    useEffect(() => {
        async function checkToken(): Promise<void> {
            const idToken = localStorage.getItem("userToken");
            if (idToken) {
                const isValid = await validateToken(idToken);
            if (!isValid) {
                localStorage.clear();
                window.location.href = window.location.origin;
                // navigate("/");
            }
            }
        }
    
        checkToken();
    }, [navigate]);


    // Default to using the first account if no account is active on page load
    useEffect(() => {
        if (msalInstance) {
            if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
                msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
            }

            // Listen for sign-in event and set active account
            msalInstance.addEventCallback(event => {
                if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                    const account = event.payload as AccountInfo;
                    msalInstance.setActiveAccount(account);
                }
            });
        }
    }, [msalInstance]);

    // Check login status
    useEffect(() => {
        const fetchLoggedIn = async () => {
            if (msalInstance) {
                setLoggedIn(await checkLoggedIn(msalInstance));
            }
        };
        fetchLoggedIn();
    }, [msalInstance]);

    if (!msalInstance) {
        return (
            <LoginContext.Provider value={{ loggedIn, setLoggedIn }}>
                {loggedIn ? (
                    <Layout />
                ) : (
                    <div className="loginButtonWrapper">
                        <LoginButton
                            onLoginSuccess={() => setLoggedIn(true)}
                        />
                    </div>
                )}
            </LoginContext.Provider>
        );
    }

    return (
        <MsalProvider instance={msalInstance}>
            <LoginContext.Provider value={{ loggedIn, setLoggedIn }}>
                {loggedIn ? (
                    <Layout />
                ) : (
                    <>
                    <div><img src={OmniReachLogo} width={400} className="loginPageLogo" /></div>
                    <div className="loginButtonWrapper">
                        
                        <div>
                        <LoginButton
                            onLoginSuccess={() => setLoggedIn(true)}
                        />
                        </div>
                    </div>
                    </>
                )}
            </LoginContext.Provider>
        </MsalProvider>
    );
};

export default LayoutWrapper;
