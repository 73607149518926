import React, { useState, ChangeEvent, useEffect, useRef } from "react";
import { Callout, Label, Text } from "@fluentui/react";
import { Button } from "@fluentui/react-components";
import { Delete24Regular } from "@fluentui/react-icons";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import attachIcon from "./../../assets/AttachIcon.svg";
import { SimpleAPIResponse, uploadFileApi, deleteUploadedFileApi, listUploadedFilesApi } from "../../api";
import { getToken } from "../../authConfig";
import styles from "./UploadFile.module.scss";
import AddFileIcon from "../../assets/AddFileIcon.svg";


const triggerFileUploadSuccessEvent = () => {
    const event = new CustomEvent("onFileUploadSuccess");
    window.dispatchEvent(event);
};

interface Props {
    className?: string;
    disabled?: boolean;
    showFileUpload?: boolean;
    triggerFileLoading?: boolean;
}

export const UploadFile: React.FC<Props> = ({
    className,
    disabled,
    showFileUpload = true,
    triggerFileLoading = false,
}: Props) => {
    const [isCalloutVisible, setIsCalloutVisible] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [deletionStatus, setDeletionStatus] = useState<{ [filename: string]: "pending" | "error" | "success" }>({});
    const [uploadedFile, setUploadedFile] = useState<SimpleAPIResponse>();
    const [uploadedFileError, setUploadedFileError] = useState<string>();
    const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);

    const client = useMsal().instance;
    const buttonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (triggerFileLoading) {
            const loadFiles = async () => {
                const idToken = await getToken(client);
                if (idToken) {
                    listUploadedFiles(idToken);
                }
            };
            loadFiles();
        }
    }, [triggerFileLoading]);

    const listUploadedFiles = async (idToken: string) => {
        listUploadedFilesApi(idToken).then((files) => {
            setIsLoading(false);
            setUploadedFiles(files); // Set the uploaded files in state
        });
    };

    const handleUploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }
    
        setIsUploading(true);
        const file: File = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
    
        try {
            const idToken = await getToken(client);
            if (!idToken) {
                throw new Error("No authentication token available");
            }
    
            const response: SimpleAPIResponse = await uploadFileApi(formData, idToken);
            setUploadedFile(response);
            setUploadedFileError(undefined);
            listUploadedFiles(idToken);
            triggerFileUploadSuccessEvent();
        } catch (error) {
            console.error("Error uploading file", error);
            setUploadedFileError("Error uploading file.");
        } finally {
            setIsUploading(false);
        }
    };
    
    const handleRemoveFile = async (filename: string) => {
        setDeletionStatus({ ...deletionStatus, [filename]: "pending" });

        try {
            const idToken = await getToken(client);
            if (!idToken) {
                throw new Error("No authentication token available");
            }
            await deleteUploadedFileApi(filename, idToken);
            setDeletionStatus({ ...deletionStatus, [filename]: "success" });
            listUploadedFiles(idToken);
        } catch (error) {
            setDeletionStatus({ ...deletionStatus, [filename]: "error" });
            console.error(error);
        }
    };

    return (
        <div className={`${className ?? ""}`}>
            <div className={styles.uploadModalWrap}>
                {showFileUpload && (
                    <Button disabled={disabled} onClick={() => setIsCalloutVisible(!isCalloutVisible)} ref={buttonRef}>
                        <img src={attachIcon} className={styles.sendIcon} alt="Attach Icon" />
                    </Button>
                )}
                {/* Display the uploaded files outside the Callout */}
                {!isCalloutVisible && !showFileUpload && (
                    <>
                        <h3 className={`font-semibold py-2`}>{uploadedFiles.length ? "Uploaded Files:" : "No Files Uploaded"}</h3>
                        {isLoading && <Text>Loading...</Text>}
                        {uploadedFiles.map((filename, index) => (
                            <div key={index} className={`flex w-full items-center gap-3 ${styles.list}`}>
                                <div className={`w-[15.625em] whitespace-nowrap overflow-hidden text-ellipsis ${styles.item}`}>{filename}</div>
                                <Button
                                    icon={<Delete24Regular />}
                                    onClick={() => handleRemoveFile(filename)}
                                    disabled={deletionStatus[filename] === "pending" || deletionStatus[filename] === "success"}
                                >
                                    {deletionStatus[filename] === "pending" && "Deleting..."}
                                    {deletionStatus[filename] === "error" && "Error deleting"}
                                    {deletionStatus[filename] === "success" && "File deleted"}
                                    {!deletionStatus[filename] && "Delete"}
                                </Button>
                            </div>
                        ))}
                    </>
                )}

                {isCalloutVisible && (
                    <Callout 
                        role="dialog"
                        gapSpace={-10}
                        className={`p-4 ${styles.callout}`}
                        target={buttonRef.current}
                        onDismiss={() => {
                            setIsCalloutVisible(false);
                            setUploadedFile(undefined);
                        }}
                        setInitialFocus
                        styles={{
                            root: { borderRadius: "20px", marginRight: "-10px", backgroundColor: "white" },
                            calloutMain: { padding: "4px 10px" },
                            beak: {
                                display: "none",
                            },
                            beakCurtain: {
                                display: "none",
                            },
                        }}
                    >
                        {showFileUpload && (
                            <>
                                <form encType="multipart/form-data">
                                    <div className={`${styles.fileUploadModalWrap}`}>
                                        <Label className={`font-bold pb-3`}>Upload File:</Label>
                                        <hr/>
                                        <div className={`${styles.fileUploadButton}`}>
                                            <label className={`inline-flex gap-2 pt-2.5 cursor-pointer font-semibold ${styles.fileUploadButton}`} htmlFor="file-upload" >
                                                <img src={AddFileIcon} className="add_file_icon" width={20} height={20} alt="Attach File"/> Upload From Computer
                                            </label>
                                            <input
                                                id="file-upload"
                                                accept=".txt, .md, .json, .png, .jpg, .jpeg, .bmp, .heic, .tiff, .pdf, .docx, .xlsx, .pptx, .html"
                                                className={styles.chooseFiles}
                                                type="file"
                                                onChange={handleUploadFile}
                                                style={{
                                                    display: "none",
                                                }}
                                            ></input>
                                        </div>
                                    </div>
                                </form>

                                {isUploading && <Text>Uploading files...</Text>}
                                {!isUploading && uploadedFileError && <Text>{uploadedFileError}</Text>}
                                {!isUploading && uploadedFile && <Text>{uploadedFile.message}</Text>}
                            </>
                        )}

                        {!showFileUpload && (
                            <>
                                <h3>{uploadedFiles.length ? "Uploaded Files" : "No Files Uploaded"}</h3>
                                {isLoading && <Text>Loading...</Text>}
                                {uploadedFiles.map((filename, index) => (
                                    <div key={index} className={styles.list}>
                                        <div className={styles.item}>{filename}</div>
                                        <Button
                                            icon={<Delete24Regular />}
                                            onClick={() => handleRemoveFile(filename)}
                                            disabled={deletionStatus[filename] === "pending" || deletionStatus[filename] === "success"}
                                        >
                                            {deletionStatus[filename] === "pending" && "Deleting..."}
                                            {deletionStatus[filename] === "error" && "Error deleting"}
                                            {deletionStatus[filename] === "success" && "File deleted"}
                                            {!deletionStatus[filename] && "Delete"}
                                        </Button>
                                    </div>
                                ))}
                            </>
                        )}
                    </Callout>
                )}
            </div>
        </div>
    );
};
