import React, { useState, useRef, useContext, useEffect } from "react";
import { Callout } from "@fluentui/react";
import styles from "./LoginModal.module.scss";
import { LoginButton } from "../../components/LoginButton";
import { LoginContext } from "../../loginContext";
import { getUsername } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import { UploadFile } from "../UploadFile";

const LoginModal = () => {
  const [isCalloutVisible, setIsCalloutVisible] = useState(false);
  const [username, setUsername] = useState<string>("");
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { loggedIn } = useContext(LoginContext);
  const { instance } = useMsal();
  const [showUserUpload, setShowUserUpload] = useState<boolean>(true);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    const fetchUsername = async () => {
      if (loggedIn) {
        const name = await getUsername(instance);
        setUsername(name ?? "");
      }
    };
    fetchUsername();
  }, [loggedIn, instance]);

  useEffect(() => {
    const handleFileUploadSuccess = () => {
      setIsCalloutVisible(true);
      setTimeout(() => setIsCalloutVisible(false), 10000);
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 10000);
    };

    window.addEventListener("onFileUploadSuccess", handleFileUploadSuccess);
    return () => {
      window.removeEventListener("onFileUploadSuccess", handleFileUploadSuccess);
    };
  }, []);

  const getInitials = (name: string) => {
    const parts = name.split(".");
    if (parts.length >= 2) {
      return `${parts[0][0]}${parts[1][0]}`.toUpperCase();
    }
    return name[0]?.toUpperCase() || "";
  };

  const handleLoginSuccess = () => {
    console.log("Login successful!");
    setIsCalloutVisible(false);
  };

  return (
    <div className="relative">
      <button className={`text-xs ${styles.profileButton}`} ref={buttonRef} onClick={() => setIsCalloutVisible(!isCalloutVisible)} >
        {loggedIn ? getInitials(username) : "UN"}
      </button>

      {isCalloutVisible && (
        <Callout
          role="dialog"
          gapSpace={-9}
          target={buttonRef}
          onDismiss={() => setIsCalloutVisible(false)}
          setInitialFocus
          styles={{
            root: { boxShadow: "0 4px 8px rgba(0,0,0,0.2)", borderRadius: "20px", marginRight: "-10px" },
            calloutMain: { padding: "8px 20px", borderRadius: "20px" },
            beak: {
              display: "none",
            },
            beakCurtain: {
              display: "none",
            },
          }}
        >

          <div className={`${styles.profileModal}`}>
            <div className={`py-3 ${styles.profileModalUser}`}>
              <span>{username}</span>
            </div>

            <hr />
            {showSuccessMessage && (
              <div className="text-green-600 text-sm py-2">File Successfully Added</div>
            )}
            {showUserUpload && (
              <UploadFile className={`mr-4 w-full ${styles.commandButton}`} disabled={!loggedIn} showFileUpload={false} triggerFileLoading={true} />
            )}
            <hr />
            <div className={`py-3 ${styles.profileModalLogout}`}>
              <LoginButton onLoginSuccess={handleLoginSuccess} />
            </div>
          </div>
        </Callout>
      )}
    </div>
  );
};

export default LoginModal;
