import React, { useEffect, useRef, useState } from "react";
import { EditorState, Plugin } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { Schema, DOMParser } from "prosemirror-model";
import { schema } from "prosemirror-schema-basic";
import "prosemirror-view/style/prosemirror.css"; // Default ProseMirror styles
import sendBtn from "./../../assets/Send.svg";
import { UploadFile } from "../../components/UploadFile";
import { SpeechInput } from "./SpeechInput";
import { requireLogin } from "../../authConfig";
import { TextSelection } from "prosemirror-state";

import styles from "./QuestionInput.module.scss";

interface Props {
    onSend: (htmlContent: string) => void;
    disabled: boolean;
    initQuestion?: string;
    placeholder?: string;
    clearOnSend?: boolean;
    showSpeechInput?: boolean;
    showUserUpload?: boolean;
    loggedIn?: boolean;
}

// Plugin to handle plain text paste
const plainTextPastePlugin = new Plugin({
    props: {
        handlePaste(view, event, slice) {
            const plainText = event.clipboardData?.getData("text/plain");
            if (plainText) {
                const { state, dispatch } = view;
                const { from, to } = state.selection;

                dispatch(
                    state.tr.replaceWith(
                        from,
                        to,
                        state.schema.text(plainText)
                    )
                );
                return true;
            }
            return false;
        },
    },
});

export const QuestionInput = ({
    onSend,
    disabled,
    initQuestion,
    placeholder = "Message OmniGPT",
    clearOnSend = true,
    showSpeechInput,
    showUserUpload,
    loggedIn,
}: Props) => {
    const editorRef = useRef<HTMLDivElement>(null);
    const viewRef = useRef<EditorView | null>(null);
    const [isComposing, setIsComposing] = useState(false);

    useEffect(() => {
        if (editorRef.current) {
            viewRef.current = new EditorView(editorRef.current, {
                state: EditorState.create({
                    schema,
                    plugins: [plainTextPastePlugin], // Add plainTextPastePlugin
                    doc: initQuestion
                        ? DOMParser.fromSchema(schema).parse(
                              document.createElement("div").appendChild(document.createTextNode(initQuestion))
                          )
                        : undefined,
                }),
                dispatchTransaction: (tr) => {
                    const newState = viewRef.current!.state.apply(tr);
                    viewRef.current!.updateState(newState);
                },
                attributes: {
                    class: styles.proseMirrorEditor,
                },
            });

            return () => {
                viewRef.current?.destroy();
            };
        }
    }, [initQuestion]);

    const handleSendMessage = () => {
        const htmlContent = viewRef.current?.dom.innerHTML || "";
        const plainTextContent = viewRef.current?.state.doc.textContent.trim();

        if (plainTextContent) {
            onSend(htmlContent);
            if (clearOnSend) {
                viewRef.current?.dispatch(
                    viewRef.current.state.tr.delete(0, viewRef.current.state.doc.content.size)
                );
            }
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        const { state, dispatch } = viewRef.current!;
        const { from } = state.selection;
    
        if (isComposing) return;
    
        if (event.key === "Backspace") {
            const isEmpty = state.doc.textContent.trim() === "";
            if (isEmpty) {
                event.preventDefault();
                dispatch(
                    state.tr.replaceWith(0, state.doc.content.size, state.schema.nodes.paragraph.create())
                );
                return;
            }
        }
    
        if (event.key === "Enter" && event.shiftKey) {
            event.preventDefault();
    
            let tr = state.tr;
            tr = tr.insert(from, state.schema.nodes.hard_break.create());
            tr = tr.setSelection(TextSelection.create(tr.doc, from + 1));
    
            dispatch(tr.scrollIntoView());
            requestAnimationFrame(() => {
                const editorEl = editorRef.current;
                if (editorEl) {
                    const selection = window.getSelection();
                    const range = document.createRange();
                    range.setStart(editorEl, editorEl.childNodes.length);
                    range.collapse(true);
                    selection?.removeAllRanges();
                    selection?.addRange(range);
                }
            });
    
            return;
        } else if (event.key === "Enter") {
            event.preventDefault();
            handleSendMessage();
        }
    };

    useEffect(() => {
        if (editorRef.current) {
            const placeholderHandler = () => {
                const content = viewRef.current?.state.doc.textContent.trim();
                editorRef.current!.dataset.placeholder = content ? "" : placeholder;
            };

            placeholderHandler();
            const observer = new MutationObserver(placeholderHandler);
            observer.observe(editorRef.current, { childList: true, subtree: true });

            return () => observer.disconnect();
        }
    }, [placeholder]);

    const disableRequiredAccessControl = requireLogin && !loggedIn;
    const sendQuestionDisabled = disabled || disableRequiredAccessControl;

    return (
        <div className={`px-4`}>
            <div className={`rounded-3xl w-full items-center gap-[10px] ${styles.questionInputContainer}`}>
                <div
                    ref={editorRef}
                    contentEditable={!disabled && !disableRequiredAccessControl}
                    className={`flex-1 min-h-[40px] rounded-md overflow-auto outline-none max-h-[220px] border-none w-full px-4 leading-6 content-center ${styles.questionInputTextArea}`}
                    onKeyDown={handleKeyDown}
                    onCompositionStart={() => setIsComposing(true)}
                    onCompositionEnd={() => setIsComposing(false)}
                    data-placeholder={placeholder}
                ></div>
                <div className={`flex justify-between px-2`}>
                    {showUserUpload && (
                        <UploadFile className={`h-[40px] self-end ${styles.commandButton}`} disabled={!loggedIn} />
                    )}
                    <div className={`flex flex-col justify-center h-[40px] self-end ${styles.questionInputButtonsContainer}`}>
                        <button
                            className={`bg-black flex flex-col justify-center p-0 rounded-full cursor-pointer`}
                            disabled={sendQuestionDisabled}
                            onClick={handleSendMessage}
                        >
                            <img src={sendBtn} className={styles.sendIcon} alt="Send Icon" />
                        </button>
                    </div>
                </div>
                {showSpeechInput && (
                    <SpeechInput
                        updateQuestion={(text) =>
                            viewRef.current?.dispatch(viewRef.current.state.tr.insertText(text))
                        }
                    />
                )}
            </div>
        </div>
    );
};
