import React from "react";
import DOMPurify from "dompurify";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import styles from "./UserChatMessage.module.scss";

interface Props {
    message: string; // The message in HTML or Markdown format
}

export const UserChatMessage = ({ message }: Props) => {
    // Preprocess the input to preserve line breaks
    const preprocessMessage = (message: string): string => {
        return message.replace(/\n/g, "  \n"); // Markdown syntax for hard break
    };

    // Sanitize the preprocessed message to prevent XSS attacks
    const sanitizedMessage = DOMPurify.sanitize(preprocessMessage(message));

    return (
        <div className={`flex justify-end mb-5 max-w-full ml-auto px-4 ${styles.UserMsgContainer}`}>
            <div className={`max-w-[95%] py-4 px-6 bg-[rgba(239,239,239,0.5)] rounded-3xl ${styles.userMessage}`}>
                <ReactMarkdown
                    children={sanitizedMessage}
                    rehypePlugins={[rehypeRaw]} // Allows rendering raw HTML
                    remarkPlugins={[remarkGfm]} // Supports GitHub Flavored Markdown
                    components={{
                        p: ({ node, ...props }) => <p {...props} style={{ whiteSpace: "pre-wrap" }} />,
                    }}
                />
            </div>
        </div>
    );
};
